<template>
  <div class="content_block">
    <div class="top">
      <b>医疗器械生产备案凭证<span v-if="isShowHead">此项信息为非必填，如需维护，请将带<i>*</i>号信息补充完整。</span></b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <template v-if="isEditing">
      <el-form :model="form" ref="JZForm" :rules="rules" label-width="180px" label-position="right">
        <el-row>
          <el-col :span="12">
            <el-form-item label="医疗器械生产备案凭证号" prop="certCode">
                <el-input placeholder="请输入医疗器械生产备案凭证号" v-model.trim="form.certCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="发证日期" prop="establishDate">
              <el-date-picker
                v-model="form.establishDate"
                type="date"
                placeholder="请选择发证日期"
                value-format="yyyy-MM-dd"
                style="width:100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期日期" prop="dueDate">
              <el-date-picker
                v-model="form.dueDate"
                type="date"
                placeholder="请选择到期日期"
                value-format="yyyy-MM-dd"
                style="width:100%"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="经营范围" prop="businessRange">
              <el-input placeholder="请输入经营范围" rows="3" v-model.trim="form.businessRange" type="textarea"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="附件信息" prop='tenantFileReqDTOs'>
              <Upload
                v-model="form.tenantFileReqDTOs"
                label="附件信息"
                buttonText="上传"
                type="single"
                :show-file-list="false"
                @success="handleFileLists"
                @remove="handleRemove"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                :maxSize="10"
                size="origin"
                :limit="10"
              >
              </Upload>
            </el-form-item>
          </el-col>
          <el-col :span="24"  v-if="form.tenantFileReqDTOs&&form.tenantFileReqDTOs.length">
            <el-form-item label="">
              <ul class="imgContainer">
                <li v-for="(img,index) in form.tenantFileReqDTOs" :key="img.url">
                  <div class="left">
                    <el-link type="primary" :href="img.url" :underline="false" target="blank">
                    <span class="name" :title='img.name'><img :src="require('@/assets/pdf.svg')" />{{ img.name }}</span>
                  </el-link>
                  </div>
                  <div class="right">
                    <i class="el-icon-success" style="color:#67C23A;margin-right: 8px;font-size:16px;"></i>
                    <i class="el-icon-delete" style="color:#409EFF;font-size:16px;cursor: pointer;" @click="handleDelete(index)"></i>
                  </div>
                </li>
              </ul>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </template>
    <el-descriptions :colon="false" :column="2" v-else style="margin-top: 10px;">
      <el-descriptions-item label="医疗器械生产备案凭证号" labelClassName="warningLabel" contentClassName="warningContent">{{ form.certCode }}</el-descriptions-item>
      <el-descriptions-item label="发证日期" labelClassName="warningLabel" contentClassName="warningContent">{{ form.establishDate }}</el-descriptions-item>
      <el-descriptions-item label="到期日期" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        {{ form.dueDate }}
      </el-descriptions-item>
      <el-descriptions-item label="经营范围" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        {{ form.businessRange }}
      </el-descriptions-item>
      <el-descriptions-item label="附件信息" :span="2" labelClassName="warningLabel" contentClassName="warningContent">
        <div v-for="img in form.tenantFileReqDTOs" :key="img.url" class="fileContainer">
          <i class="el-icon-document" style="color:#FF5959;margin-right:4px;"></i><el-link type="primary" :href="img.url" :underline="false" target="blank"> {{ img.name }}</el-link>
        </div>
      </el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: '',
  components: {
    Upload: ()=>import('@/components/common/upload'),
  },
  props: {
    certificateLists: {
      type: Array,
      default () {
        return [];
      },
    },
    type: {
      type: String,
      default: '18',
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    activeName: {
      handler (val) {
        if (val === 'ProductionRecordCertificate') {
          let info = this.certificateLists.find(i=>i.type === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            establishDate: info && info.establishDate ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD') : '',
            dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
          } : {
            establishDate: '',
            dueDate: '',
            tenantFileReqDTOs: [],
            certCode: '',
            type: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    certificateLists: {
      handler (val) {
        if (val && val.length) {
          let info = val.find(i=>i.type === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            establishDate: info && info.establishDate ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD') : '',
            dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
          } : {
            establishDate: '',
            dueDate: '',
            tenantFileReqDTOs: [],
            certCode: '',
            type: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(()=>{
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      let info = this.certificateLists.find(i=>i.type === this.type);
      info = info ? JSON.parse(JSON.stringify(info)) : null;
      this.form = info ? {
        ...info,
        establishDate: info && info.establishDate ? this.$options.filters['date-format'](info.establishDate, 'YYYY-MM-DD') : '',
        dueDate: info && info.dueDate ? this.$options.filters['date-format'](info.dueDate, 'YYYY-MM-DD') : '',
      } : {
        establishDate: '',
        dueDate: '',
        tenantFileReqDTOs: [],
        certCode: '',
        type: this.type,
      };
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            type: this.type,
          }));
          this.$emit('updateMaterial', {...this.form, tenantFileReqDTOs}, this.type);
          this.isEditing = false;
        }
      });
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid =>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            type: this.type,
          }));
          this.$emit('updateMaterial', {...this.form, tenantFileReqDTOs}, this.type);
        }
      });
    },
    handleRemove () {
      console.log(arguments[0]);
    },
    handleFileLists () {
      console.log(arguments[0]);
    },
    handleDelete (index) {
      this.form.tenantFileReqDTOs.splice(index, 1);
    },
    // 发证日期验证
    validateEstablishDate (rule, value, callback) {
      this.$refs.JZForm.clearValidate();
      // 如果到期日期没选，cb
      if (!this.form.dueDate || !value) {
        callback();
      } else {
        // 到期日期有，进行判断
        if (this.compareDate(value, this.form.dueDate)) {
          // 如果起始在结束之前
          callback();
        } else {
          callback(new Error('发证日期不可大于到期日期，请重新选择'));
        }
      }
    },
    validateDueDate (rule, value, callback) {
      this.$refs.JZForm.clearValidate();
      if (!this.form.establishDate || !value) {
        callback();
      } else {
        // 发证日期
        if (this.compareDate(this.form.establishDate, value)) {
          callback();
        } else {
          callback(new Error('到期日期不能小于发证日期，请重新选择'));
        }
      }
    },
    compareDate (start, end) {
      return new Date(end).getTime() >= new Date(start).getTime();
    },
  },
  data () {
    return {
      isEditing: false,
      rules: {
        tenantFileReqDTOs: [
          {
            required: true,
            message: '请选择附件信息',
            trigger: ['blur', 'change'],
          },
        ],
        establishDate: [
          {
            required: true,
            message: '请选择发证日期',
            trigger: ['blur', 'change'],
          },
          {trigger: ['blur', 'change'], validator: this.validateEstablishDate},
        ],
        dueDate: [
          {
            required: true,
            message: '请选择到期日期',
            trigger: ['blur', 'change'],
          },
          {trigger: ['blur', 'change'], validator: this.validateDueDate},
        ],
        certCode: [
          {
            required: true,
            message: '请输入医疗器械生产备案凭证',
            trigger: ['blur', 'change'],
          },
        ],
      },
      form: {
        establishDate: '',
        dueDate: '',
        tenantFileReqDTOs: [],
        certCode: '',
        type: this.type,
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 40px;
    line-height: 40px;
    display: flex;
    b>span{
      font-family: PingFangSC-Regular;
      font-size: 12px;
      color: rgb(95, 106, 122);
      letter-spacing: 0px;
      margin-left: 10px;
      font-weight: normal;
      i{
        font-style: normal;
        font-family: PingFangSC-Regular;
        font-size: 12px;
        color: #FF5959;
        letter-spacing: 0;
        margin-left: 3px;
        margin-right: 3px;
      }
    }
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 180px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 20px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 20px;
  display: flex;
  flex-direction: column;
  .fileContainer{
    flex: 1;
  }
}
.imgContainer{
  list-style: none;
  li{
    height: 40px;
    line-height: 20px;
    background: #F5F7FA;
    border-radius: 4px;
    display: flex;
    width:500px;
    padding: 10px 8px;
    margin-bottom: 8px;
    .left{
      flex: 1;
      display: flex;
      .el-link.el-link--primary {
        width:440px;
        display:inline-block;
        span.el-link--inner{
          width: 100%;
          span.name{
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            width: 420px;
            display:inline-block;
          }
        }
      }
    }
    img{
      width:16px;
      height:16px;
      margin-right: 4px;
    }
  }
}
</style>
